@tailwind base;
@tailwind components;
@tailwind utilities;


.mentions{
   margin:0;
}
.mentions__input
{
   @apply  border-0 focus:border-b focus:border-indigo-600 focus:ring-0  placeholder:text-lg  text-lg font-medium;
}


.mentions--multiLine .mentions__control {
   min-height: 27px;
   max-height: 86px;
   overflow-y:auto;   
 }
 .mentions--multiLine .mentions__highlighter {
   border: 1px solid transparent;
   padding: 0px;
   line-height: 1.75rem;
 }
 .mentions--multiLine .mentions__input {
   overflow-y: auto;
   padding: 0px;
  max-height:86px;
 }

 .mentions--singleLine .mentions__control {
   display: inline-block;
   width: 180px;
 }
 .mentions--singleLine .mentions__highlighter {
   padding: 1px;
   border: 2px inset transparent;
 }
 .mentions__suggestions {
   margin-left: 4px;
   margin-top:24px;
   min-width:48px;
   max-width: 320px;

 }
 .mentions__suggestions__list {
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 4px;
 }
 .mentions__suggestions__item {
   padding: 5px 15px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.15);
 }
 .mentions__suggestions__item--focused {
   background-color: rgba(224,231,255);   
 }
